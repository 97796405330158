/* Add these styles to your CSS file */
.layout-wrapper {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }
  
  .layout-container {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }
  
  .layout-page {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  
  .sidebar {
    /* Add your sidebar styles here */
    flex: 0 0 250px; /* Fixed width for the sidebar */
    background-color: #f3f4f6;
    overflow-y: auto;
    position: sticky;
    top: 0;
    height: 100vh;
  }
  .data-btn {
    width: 100px;
    height: 35px;
    border-radius: 5px;
    border: none;
    background-color: #2980b9;
    color: white;
}
  .navbar {
    /* Add your navbar styles here */
    flex: 0 0 auto;
    background-color: #333;
    color: white;
    padding: 10px 20px;
  }
  
  .content-wrapper {
    padding: 20px;
    flex: 1 1 auto;
    overflow-y: auto;
  }
  /* Add any additional styles you need */
  .input-cell {
    display: flex;
    align-items: center;
  }
  
  .input-cell input {
    width: 100%;
    padding: 6px;
  }
  .custom-white-card {
    display: inline-block;
    vertical-align: top;
  }
  /* MODAL  */

  .uploadModal-content {
    display: flex;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #888;
    width: 100% !important;

}
.close {
  cursor: pointer;
  margin-left: 562px;
  position: relative;
  top: -51px;
}
.file-close {
  cursor: pointer;
  margin-left: 562px;
  position: relative;
  top: -51px; 
}
.menu-vertical {
  max-width: 250px;
}
/* Modal Container */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  text-align: center;
}

.row {
  display: flex;
}

.column {
  flex: 1;
  margin: 0 10px; /* Adjust the margin as per your design */
}
/* date picker css */
.date-filter {
  max-width: 600px;
  margin: 0;
  padding: 10px; /* Reduced top margin */
  background-color: #f5f5f5;
  border-radius: 10px;
}

.filter-heading {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}

.input-container {
  display: flex;
  justify-content: space-between;
}

.date-input-group {
  margin-bottom: 5px; /* Reduced space between input groups */
}

.input-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-right: 5px;
  display: block;
  color: #696cff;
}

.date-input {
  width: 150px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 5px 10px;
}

.date-input:focus {
  outline: none;
  border-color: #6dabe4;
}
.custom-date-input {
  width: 150px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 5px 10px;
}

.custom-date-input:focus {
  outline: none;
  border-color: #6dabe4;
}

.react-datepicker__day {
  &.react-datepicker__day--highlighted {
    background-color: #ffc107;
    color: #000;
  }

  &.react-datepicker__day--keyboard-selected {
    background-color: #6dabe4;
    color: #fff;
  }
}

.react-datepicker__day--outside-month {
  color: #ccc;
}
/* pagination */

.pagination {
  display: flex;
  list-style: none;
  padding: 20px;
  justify-content: center;
}
.pagination li {
  margin: 0 5px;
  display: inline-block;
}

.pagination a {
  text-decoration: none;
  font-size: 16px;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ccc;
}

.pagination .active a {
  background-color: #ff5733;
  color: #fff;
}

.pagination .arrow-icon {
  font-size: 20px;
  vertical-align: middle;
}
.table-header {
  position: sticky;
  top: 0;
  background-color: #fff;
}

/*filters */
.filter-container {
  display: flex;
  align-items: center;
}

.filter-group {
  margin-right: 10px;
}

.filter-label {
  margin-right: 5px;
}

.date-filter {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.date-input-group {
  position: relative;
}

.date-input-group .react-datepicker-wrapper {
  z-index: 2; /* Ensure the date picker is above other elements */
}

.input-container {
  display: flex;
  align-items: center;
}

.date-input-group,
.filter-group {
  margin-right: 20px;
}
.filter-btn {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.filter-btn:hover {
  background-color: #0056b3;
}
.date-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.date-input-group,
.filter-group {
  margin-right: 20px;
}
.date-input,
.custom-date-input,
.filter-btn {
  width: 150px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 5px 10px;
}

.filter-btn {
  background-color: #2980b9;
  color: white;
}
.data-btn.filter-btn.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.data-btn.filter-btn.disabled:hover {
  cursor: not-allowed; 
}

