.details {
  display: flex;
  gap: 10px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}
.details_and_journey {
  display: flex;
  flex-direction: column;
  width: 70vw; 
  overflow-y: auto;
}

.sales {
  width: 30vw;
  /* height: 100%; */
  /* overflow-y: hidden; */

}
.student_journey {
  max-height: 40vh;
  /* margin-top: 40px; */ 
  overflow-y: auto;
}
.review{
  height:60px;
  /* background-color: antiquewhite; */
  display: flex;
  align-items: center;
  gap: 50px;
}
.student_details_table {
  display: flex;
  gap: 12vw;
  /* justify-content: space-around/; */
}
.thead,
.tddetail {
  /* padding: 13px 20px; */
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.thead {
  /* background-color: #c8fadb; */
  /* background-color: #cdfadb; */ 
}
.thtd {
  gap: "10px";
}
.sales_card {
  height: 90%;
}
.journey_card {
  height: 100%;
}
.title {
  font-size: 23px;
  font-weight: 500;
  margin: 6px;
}
.card {
    background-color: #fff;  
    color : black;
}
/* .btn,.btn-primary { 
    background-color: #fff;  
    color : black;
    border: none;
}  */
.btn :hover 
{
    
}
.table-wrapper {
  max-height: 300px; /* Set the maximum height of the table wrapper */
  overflow-y: auto; /* Enable vertical scrollbar if content exceeds the height */
}

.Modal {
  height: 100%;
  outline: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.Modal-box {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 600px;
}
.Modal-box-heading {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  flex: 1 1 auto;
  padding: 20px 24px;
  color: #201658;
}
* 
{
    ::-webkit-scrollbar {
        width: 10px;
      }
    
      ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 5px;
      }
}
.disabled 
{
  cursor: not-allowed; 
  background-color: grey;
}
.popup {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    z-index: 999;
    cursor: pointer;
    opacity: 0.9;
  }
  
  .success {
    background-color: #4CAF50;
  }
  
  .error {
    background-color: #f44336;
  }
  
  .popup .close {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  .title h2 {
    color: #696cff;
    margin-top: 10px;
    font-size: 30px;
  }
  
  

